const en = {
  GREETINGS: {
    SIGNUP: "Sign Up",
    LOGIN: "Login",
  },

  FORM_FIELDS: {
    USERNAME: "Username",
    EMAIL: "E-mail",
    PASSWORD: "Password",
    RETYPE_PASSWORD: "Retype Password",
    SIGNUP: "Sign Up",
    LOGIN: "Login",
    SIGNUP_ALT_TEXT: "Existing user?",
    LOGIN_ALT_TEXT: "New user?",
    REFERRAL_CODE: "Enter Referral Code",
    REFERRED_BY: "Username of Referrer"
  },

  ERRORS: {
    EMPTY_EMAIL: "Email is required",
    INVALID_EMAIL: "Invalid email",
    EMPTY_PASSWORD: "Password is required",
    PASSWORD_MISMATCH: "Passwords do not match",
    AUTH: "Incorrect email/password",
  },

  APP: {
    TITLE: "Pafu",
    DESC: "The science of motivation: stake your success, challenge friends, and gamify goals.",
  }
};

export default en;
