import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs} from "firebase/firestore";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// if (!process.env.REACT_APP_FIREBASE_CONFIG) {
//   console.error("REACT_APP_FIREBASE_CONFIG must be defined");
//   console.log("ENV: ", process.env);
// }
const firebaseConfig = {
  apiKey: "AIzaSyBq5XfuwfoVswg-J2E1hxjmHhkK7Z5bHyk",
  authDomain: "pafu-4542a.firebaseapp.com",
  projectId: "pafu-4542a",
  storageBucket: "pafu-4542a.appspot.com",
  messagingSenderId: "815553322823",
  appId: "1:815553322823:web:d16fe6e6e73d8eb4bdf662",
  measurementId: "G-B5FK8CB8KV"
};
export function initialize() {
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

export function attachAuthListener(handler) {
  return firebase.auth().onAuthStateChanged(user => {
    handler(user);
  });
}

// export async function createNewUser(username, email, password) {

//   await firebase.auth().createUserWithEmailAndPassword(email, password);
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function generateUniqueReferralCode(length = 8) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let referralCode, exists;

    do {
        // Generate random referral code
        referralCode = Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');

        // Query Firestore for existing referral codes
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("referralCode", "==", referralCode));
        const querySnapshot = await getDocs(q);
        exists = !querySnapshot.empty;

    } while (exists);

    return referralCode;
}

// input : referral code being inputted by new user (code is referralCode from existing user)
// output: true/false whether referredCode exists in firebase 'users' collection

export const checkReferralCode = async (referredCode) => {
  if (!referredCode.trim()) return false;

  try {
    const codesRef = collection(db, "users"); // Update with your collection name
    const q = query(codesRef, where("referralCode", "==", referredCode));
    const querySnapshot = await getDocs(q);
  
    return !querySnapshot.empty; // Returns true if the code exists, false otherwise
  } catch (error) {
    console.error("Error checking referral code:", error);
    return false;
  }
};

export async function createNewUser(username, email, password, referredBy, referredCode) {
  try {
    // Create user with email and password in Firebase Authentication
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
    // Get the user ID from the created user
    const userId = userCredential.user.uid;

    // Add a document in Firestore in the 'users' collection with the new user's details
    await firebase.firestore().collection('users').doc(userId).set({
      username: username,
      email: email,
      skips: 3,
      referralCode: await generateUniqueReferralCode(),
      referredBy: referredBy,
      referredCode: referredCode,
      circles: []  // Initialize an empty array for circles
    });

    console.log('User created and added to Firestore successfully.');
  } catch (error) {
    console.error('Error creating new user: ', error);
    throw error;  // Optionally re-throw to handle errors outside of this function
  }
}

export async function signIn(email, password) {
  await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function signOut() {
  await firebase.auth().signOut();
}
