import React from "react";
import { createPortal } from "react-dom";
import { Redirect } from "react-router-dom";
import { authStates, withAuth } from "../components/auth";
import { signOut } from "../utils/firebase";
import Loader from "../components/loader";
import { withRouter } from "react-router-dom";
import { STATUS_COLORS } from '../components/constants';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from 'react-router-dom'; // Add this import

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentMethod from '../components/AddPaymentMethod';
import 'firebase/compat/functions';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Ensure Firestore is imported correctly

import LearnMore from "./learn-more";
import FAQ from "./faq"; // Import the FAQ component

// stripe key
const stripePromise = loadStripe('pk_live_51P6402GSxt9qdcRfx1JHdTt8CoVcxPAQvjFGJuTUZl1XoUsjy02SPkTvwocsrgXyyiBZBS5vvrtaFVHQL83JaY3V00lckKDKjS');

class Payment extends React.Component {
  state = {
    goals: [],
    createCircle: false,
    joinCircle: false,
    circleView: false,
    learnMore: true,
    showFAQ: false,  // State to control the FAQ modal
    verifyGoalOpen: false,
    currentVerifyCircleId: null,
    currentTestimonialIndex: 0, // State to control the current testimonial
    testimonials: [
      { text: "Pafu has encouraged me to reach my daily goals of exercising in a way that doesn't put too much pressure and stress on me, so I can stay consistent even if I miss a day.", author: "Ryan" },
      { text: "Pafu has had a tremendous impact on my physical health. My walking heart rate has decreased by 20 bpm thanks to my daily exercise challenge.", author: "Vishal" },
      { text: "I've been habit tracking for over a year, and Pafu is the most effective system I've used. It helped me stick to working out 3 times a week, wake up at 7 AM, do 20 pull-ups a day, & stop a 5-year hair-picking disorder.", author: "AnhPhu" },
    ],
    showPaymentHistory: false,
    totalPayments: 0,
    paymentsList: [],
    loadingPayments: false
  };

  handleShowPaymentHistory = async () => {
    this.setState({ loadingPayments: true });
    
    try {
      const customerDocRef = firebase.firestore().collection('stripe_customers').doc(this.props.user.uid);
      const customerDoc = await customerDocRef.get();
      
      if (!customerDoc.exists || !customerDoc.data().customer_id) {
        alert("We don't have your card information on file.");
        this.setState({ loadingPayments: false });
        return;
      }

      this.setState({
        showPaymentHistory: true,
      })
      
      const getUserPayments = firebase.functions().httpsCallable('getUserPayments');
      const response = await getUserPayments({ stripeCustomerId: customerDoc.data().customer_id });
      
      const { totalAmountNet, payments } = response.data;

      this.setState({
        totalPayments: totalAmountNet,
        paymentsList: payments,
        loadingPayments: false,
      })
  
    } catch (error) {
      console.error("Error fetching payments: ", error);
      this.setState({ loadingPayments: false });
      alert("An error occurred while fetching payment history. Please try again later.");
    }
  };

  closePaymentHistory = () => {
    this.setState({ showPaymentHistory: false });
  };

  fetchUserCircles = () => {
    const db = firebase.firestore();
    const userRef = db.collection("users").doc(this.props.user.uid);

    userRef.get().then((doc) => {
      if (doc.exists) {
        const circlesIds = doc.data().circles; // Assuming 'circles' is an array of circle IDs
        this.fetchCirclesDetails(circlesIds);
      }
    }).catch(error => {
      console.error("Error fetching user's circles: ", error);
    });
  };

  fetchCirclesDetails = (circlesIds) => {
    const db = firebase.firestore();
    const promises = circlesIds.map(circleId =>
      db.collection("circles").doc(circleId).get()
        .then(doc => {
          if (!doc.exists) {
            console.log(`No such document with id ${circleId}!`);
            return null; // Skip this document and filter it out later
          }
          return doc;
        })
        .catch(error => {
          console.error(`Error getting document with id ${circleId}: `, error);
          return null; // Skip this document and filter it out later
        })
    );

    Promise.all(promises).then(circlesSnapshot => {
      const validDocs = circlesSnapshot.filter(doc => doc !== null);

      const goalsData = validDocs.map(doc => {
        const circleData = doc.data();
        const userHistory = circleData.userData.find(u => u.userId === this.props.user.uid)?.userHistory ?? [];
        const circleId = doc.id;

        return {
          name: circleData.goal,
          status: userHistory,
          circleId: circleId,
        };
      });

      this.setState({ goals: goalsData });
    }).catch(error => {
      console.error("Error fetching circles details: ", error);
    });
  };

  handleSignOut = () => {
    signOut()
      .then(() => {
        console.log("Signed Out");
      })
      .catch(e => {
        console.log("Error signing out", e);
      });
  };

  navigateToCreateCircle = () => {
    this.setState({ createCircle: true });
  };

  navigateToJoinCircle = () => {
    this.setState({ joinCircle: true });
  };

  navigateToCircleView = (circleId) => {
    console.log("navigateToCircleView: ", circleId);
    this.setState({ circleView: true, currentCircleId: circleId });
  };

  openVerifyGoal = (circleId) => {
    this.setState({
      verifyGoalOpen: true,
      currentVerifyCircleId: circleId,
    });
  };

  renderDashboard = () => {
    return createPortal(
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto">
        <LearnMore isOpen={this.state.learnMore} onClose={() => this.setState({ learnMore: false })} />
      </div>,
      document.getElementById('modal-root')
    );
  };

  renderSwitch = () => {
    return (
      <Elements stripe={stripePromise}>
        <AddPaymentMethod />
      </Elements>
    );
  };

  renderPaymentHistory = () => {
    const { paymentsList, totalPayments, loadingPayments } = this.state;
  
    return createPortal(
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
          <div className="sticky top-0 bg-white p-4 border-b border-gray-200 flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-gray-800">Payment History</h2>
            <button onClick={this.closePaymentHistory} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div className="p-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Total Loss: {" "}
              {loadingPayments ? 
                (<Skeleton width={80}/>) : (<span className="text-green-500">${totalPayments.toFixed(2)}</span>)}
            </h3>
            {!loadingPayments ? paymentsList.length > 0 ? (
              <ul className="space-y-4">
                {paymentsList.map((payment) => (
                  <li key={payment.id} className="bg-gray-50 p-4 rounded-lg shadow">
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="text-sm text-gray-600">Date: {new Date(payment.date).toLocaleDateString()}</p>
                        <p className="text-lg font-medium text-gray-800">Amount: ${payment.amount.toFixed(2)}</p>
                        <p className={`text-sm ${payment.status === 'succeeded' ? 'text-green-500' : 'text-red-500'}`}>
                          (Status: {payment.status.charAt(0).toUpperCase() + payment.status.slice(1)})
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-medium text-gray-800">
                          Net Amount: ${payment.netAmount.toFixed(2)}
                        </p>
                        {payment.refunded && (
                          <p className="text-sm text-red-500">
                            Refunded: ${payment.refundAmount.toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              ) : ( 
                <p className="text-gray-600 text-center">No payment history available.</p>
              ): 
              <ul>
                <li className="p-4 rounded-lg shadow">
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="text-sm text-gray-600"><Skeleton width={120}/></p>
                      <p className="text-lg font-medium text-gray-800"><Skeleton width={140} height={24}/></p>
                      <p>
                        <Skeleton width={100}/>
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-800">
                        <Skeleton width={80}/>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              }
          </div>
        </div>
      </div>,
      document.body
    );
  };

  onCircleCreated = () => {
    this.setState({ createCircle: false }); // Close the modal
    this.fetchUserCircles(); // Optionally refresh circles list or navigate
  };

  onCircleLeft = () => {
    this.setState({ circleView: false }); // Close the modal
    this.fetchUserCircles(); // Refresh circles list or navigate
  };

  toggleLearnMore = (event) => {
    if (event.detail === 2 || event.button === 2) {
      // Double click or right click
      event.preventDefault();
      this.props.history.push('/web');
    } else {
      // Single left click
      this.setState({ learnMore: !this.state.learnMore });
    }
  };

  toggleFAQ = () => {
    this.setState({ showFAQ: !this.state.showFAQ });
  };

  componentDidMount() {
    this.initializeSwiper();
    this.testimonialInterval = setInterval(this.nextTestimonial, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.learnMore !== prevState.learnMore) {
      document.body.style.overflow = this.state.learnMore ? 'hidden' : 'auto';
    }
  }

  componentWillUnmount() {
    clearInterval(this.testimonialInterval);
    document.body.style.overflow = 'auto'; // Ensure scroll is re-enabled when component unmounts
  }

  initializeSwiper = () => {
    let currentIndex = 0;
    const slides = document.querySelectorAll('.swiper-slide');
    const totalSlides = slides.length;

    function showSlide(index) {
      slides.forEach((slide, i) => {
        slide.style.display = i === index ? 'block' : 'none';
      });
    }

    function nextSlide() {
      currentIndex = (currentIndex + 1) % totalSlides;
      showSlide(currentIndex);
    }

    showSlide(currentIndex);
    setInterval(nextSlide, 5000);
  };
  

  nextTestimonial = () => {
    this.setState(prevState => ({
      currentTestimonialIndex: (prevState.currentTestimonialIndex + 1) % prevState.testimonials.length // Dynamically handles testimonials based on length
    }));
  };

  render() {
    const { authState, user } = this.props;
    const { testimonials, currentTestimonialIndex } = this.state;


    if (authState === authStates.INITIAL_VALUE) {
      return <Loader />;
    }

    if (authState === authStates.LOGGED_OUT) {
      return <Redirect to="/login" />;
    }

    const modalRoot = document.getElementById('modal-root');


    const currentTestimonial = testimonials[currentTestimonialIndex];

    return (
<div className="flex flex-col min-h-screen background">
  <div className="flex-grow overflow-y-auto">
    <div className="flex flex-col items-center p-4">
      <div className="w-full max-w-3xl">
        <div className="flex flex-col items-center w-full mb-4">
          <h1 className="text-center w-full mt-4">Welcome to Pafu!</h1>
          <div className="flex justify-center items-center h-full">
            {this.state.learnMore && this.renderDashboard()}
          </div>
        </div>
        <p className="text-gray-600 mb-4">
          Research shows habit formation is more effective when you stake money. Add your card to download the app. You will not be charged unless you fail to complete a habit. If you ever have an unfair charge, just email us and we will immediately refund you.
        </p>
        <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2 flex flex-col">
            <div className="flex-grow bg-white bg-opacity-30 rounded-md shadow-sm p-4 flex flex-col justify-center items-center">
              <p className="text-gray-600 text-base md:text-lg italic text-center mb-4">
                "{currentTestimonial.text}"
              </p>
              <p className="text-gray-500 text-sm md:text-base text-center">
                - {currentTestimonial.author}
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex flex-col">
            <div className="flex-grow rounded-md flex flex-col justify-center items-center">
              {this.renderSwitch()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center w-full py-6 md:py-4 space-y-4 md:space-y-0 md:space-x-4">
      <button 
        onClick={this.toggleLearnMore} 
        onContextMenu={this.toggleLearnMore}
        className="button w-full md:w-auto"
      >
        Learn More
      </button>
      <button
          onClick={this.handleShowPaymentHistory}
          className="button w-full md:w-auto"
      >
        Show Payment History
      </button>
      {/* {this.state.loadingPayments && <p className="">Loading...</p>} */}
              <button onClick={this.toggleFAQ} className="button w-full md:w-auto">
                FAQ
              </button>
              <button className="icon-button w-full md:w-auto flex justify-center items-center" onClick={this.handleSignOut}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                  />
                </svg>
              </button>
            </div>
        </div>
      </div>
      {this.state.showFAQ && createPortal(
        <FAQ isOpen={this.state.showFAQ} onClose={this.toggleFAQ} />,
        document.getElementById('modal-root')
      )}
      {this.state.showPaymentHistory && this.renderPaymentHistory()}
    </div>
    );
  }
}
export default withRouter(withAuth(Payment));
