import React from 'react';
import { createPortal } from 'react-dom';

const FAQ = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const modalContent = (
    <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white w-3/5 rounded-md overflow-hidden'>
            <div className='flex justify-between items-center p-4 bg-white sticky top-0 z-10'>
            <h1>Frequently Asked Questions</h1>
            <button onClick={onClose} className='text-lg font-semibold'>X</button>
            </div>
            <div className='p-4 overflow-y-auto max-h-[calc(80vh-80px)]'>
            <ul>
            <li className="mb-2">
              <strong>Where does my money go?</strong>
              <p>During the beta testing period, 15% of all profit will go to charity, 15% will support Pafu’s growth, and 70% will be distributed to top users of select circles.</p>
            </li>
            <li className="mb-2">
              <strong>I want to start, but won't I lose a lot of money?</strong>
              <p>You're always in control. You can commit as little as $1 per habit, so even if you fail 10 times in a month, you’d only lose $10—less than the cost of a couple of coffees. You decide how much to risk, making it a low-cost way to stay accountable and build lasting habits.</p>
            </li>
            <li className="mb-2">
              <strong>How is my card information handled?</strong>
              <p>Have you used Amazon, Shopify, or Lyft before? They all trust Stripe to process payments securely—and so do we. We never store your card information; Stripe handles all transactions with industry-leading encryption and PCI-DSS compliance to keep your financial data safe.</p>
            </li>
            <li className="mb-2">
              <strong>What happens if I want out?</strong>
              <p>You can leave any Pafu circle at any time with the click of one button.</p>
            </li>
            <li className="mb-2">
              <strong>How does Pafu verify that I complete my tasks?</strong>
              <p>Pafu doesn’t verify your tasks—your friends do! When you report a completed task, your Pafu circle reviews the evidence you submit. However, for the circles that pay out money, we hand-check the submissions to ensure fairness and accuracy.</p>
            </li>
            <li className="mb-2">
              <strong>Can’t I cheat the system by submittting fake evidence and telling my friends to allow it?</strong>
              <p>Yes, in most cases. However, in select circles where top users can earn money by completing the habit most often, additional measures are in place to ensure fairness and prevent cheating.</p>
            </li>
        </ul>
            </div>
        </div>
    </div>
  );

  return createPortal(modalContent, document.getElementById('modal-root'));
};

export default FAQ;
