import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import Signup from "./pages/signup";
import CreateCircle from "./pages/create-circle";
import JoinCircle from "./pages/join-circle";
import LearnMore from "./pages/learn-more";
import Payment from "./pages/payment";
import { SkeletonTheme } from "react-loading-skeleton";

import "./App.css";
import CircleView from "./pages/circle-view";

function App() {
  return (
    
      <Router>
        <div className="App">
          <Switch>
            <Route path="/web">
              <Home />
            </Route>
            <Route path="/payment">
              <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
                <Payment />
              </SkeletonTheme>
            </Route>
            <Route path="/">
              <Login />
            </Route>
            {/* <Route path="/signup">
              <Signup />
            </Route> */}
            <Route path="/create-circle">
              <CreateCircle />
            </Route>
            <Route path="/join-circle">
              <JoinCircle />
            </Route>
            <Route path="/learn-more">
              <LearnMore />
            </Route>
            <Route path="/circle-view/:circleId" component={CircleView} />
            
          </Switch>
        </div>
      </Router>
   
  );
}

export default App;
