import en from "./i18n";
import { checkReferralCode } from "./firebase";

export function validateEmail(email) {
  if (!email) {
    return en.ERRORS.EMPTY_EMAIL;
  }
  //Check email regex
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (!emailRegex.test(email)) {
    return en.ERRORS.INVALID_EMAIL;
  }
}

export function validatePassword(password) {
  if (!password) {
    return en.ERRORS.EMPTY_PASSWORD;
  }
}

export function validateEmailPassword(email, password) {
  const msg = validateEmail(email);

  if (msg) {
    return msg;
  }

  return validatePassword(password);
}

export async function validateReferredCode(referredCode) {
   //Validate referral code
   if (!referredCode || typeof referredCode !== "string") {
    console.log(referredCode);
    throw { code: "auth/invalid-referral-code" }; // Throw an error instead of returning
  }

  const isValid = await checkReferralCode(referredCode.trim());
  if (!isValid) {
    throw { code: "auth/invalid-referral-code" }; // Throw an error if invalid
  }
}